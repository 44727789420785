import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {
  Typography,
  Breadcrumbs,
  Box,
  Link,
  FormControl,
  MenuItem,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Select,
  TableRow,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";

const BatchJobHistory = (props) => {
  var queryStringData = window.location.search;
  var chars = queryStringData.split('=');
  let jobTypeTemp = (chars[1]) ? chars[1].toUpperCase() : "PRODUCT";
  let jobCategory = [
    {
      name: "Product",
      value: "PRODUCT"
    },
    {
      name: "Inventory",
      value: "PRODUCT_INV"
    },
    {
      name: "Customer",
      value: "CUSTOMER"
    },
    {
      name: "Sales",
      value: "VOUCHER"
    }]
  let dateRange = [
    {
      name: "Last 1Day",
      value: 1
    },
    {
      name: "Last 7days",
      value: 7
    },
    {
      name: "Last 15days",
      value: 15
    },
    {
      name: "Last 30days",
      value: 30
    }]
  const pageSize = 10;
  const history = useHistory();
  const [count, setCount] = useState();
  const [jobDate, setDateRange] = useState("");
  const [jobType, setJobType] = useState(jobTypeTemp);
  const [tempJobType, setTempJobType] = useState(jobTypeTemp);
  const [data, setData] = useState([]);
  // const [jobCategory, setJobCategoryData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize, jobType: jobType, dayCount: 0 });
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("authToken");
  const loadData = async () => {
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/batch/jobhistory`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.rows);
    } catch (err) {
      setCount(0);
      setData([]);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      page: 1,
    });
    loadData();
  };

  const resetQuery = () => {
    window.location.reload();
  };

  return (
    <div>
      {props.modal ? null : (
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Link color="inherit" href="" onClick={() => history.push("/datasync")}>
                  Run Jobs
                </Link>
                <Typography component="span" color="textPrimary">
                  History
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </Grid>
      )}
      <div className="user-filter-grids">
        <Typography variant="h6">
          View History
        </Typography>
        <form onSubmit={handleSearch}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={props.modal ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Job Category</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ display: "flex" }}
                >
                  <Select
                    name="jobType"
                    style={{ display: "flex" }}
                    value={tempJobType}
                    onChange={(e) => {
                      setTempJobType(e.target.value);
                    }}
                    variant="outlined"
                    size="small"
                  >
                    {jobCategory.map((category, index) => {
                      return (
                        <MenuItem key={index + 1} value={category.value}>
                          {category.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={props.modal ? 4 : 2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Date Range</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ display: "flex" }}
                >
                  <Select
                    name="jobDate"
                    style={{ display: "flex" }}
                    value={jobDate}
                    onChange={(e) => {
                      setDateRange(e.target.value);
                    }}

                    variant="outlined"
                    size="small"
                  >
                    {dateRange.map((date, index) => {
                      return (
                        <MenuItem key={index + 1} value={date.value}>
                          {date.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container justify={"flex-end"} xs={12}>
              <Box mt={2} mr={0}>
                <Button variant="contained" color="primary" size="small" onClick={async () => {
                  await setJobType(tempJobType)
                  await setQuery({
                    ...query,
                    jobType: tempJobType !== "All" ? tempJobType : "PRODUCT",
                    dayCount: jobDate > 0 ? jobDate : 0,
                    page: 1,
                  });
                  }}
                  >
                  Search </Button></Box>
              <Box mt={2} mr={0}>
                <Button size="small" style={{ marginLeft: '10px' }} type="reset" onClick={() => resetQuery()}
                  variant="contained" color="secondary">
                  clear</Button></Box>
            </Grid>
          </Grid>
        </form>
      </div>
      {(jobType === "PRODUCT" || jobType === "PRODUCT_INV") ?
        <div style={{ paddingTop: 30 }}>
          <TableContainer component={Paper} >
            <Table style={{ paddingTop: 10 }} size="small">
              <TableHead>
                <TableRow>
                  <StyledHeaderCell align="left" colSpan={2} className="paddingStyle">Date</StyledHeaderCell>
                  <StyledHeaderCell align="left" className="paddingStyle"></StyledHeaderCell>
                  <StyledHeaderCell align="left" className="paddingStyle"> {(jobType === "PRODUCT_INV") ? "Inventory in File" : "Product in File"}</StyledHeaderCell>
                  <StyledHeaderCell align="left" className="paddingStyle">{(jobType === "PRODUCT_INV") ? "Newly Created Inventory" : "Newly Created Products"}</StyledHeaderCell>
                  <StyledHeaderCell align="left" className="paddingStyle">{(jobType === "PRODUCT_INV") ? "Updated Inventory" : "Updated Products"}</StyledHeaderCell>
                  <StyledHeaderCell align="left" className="paddingStyle">
                    Status
                  </StyledHeaderCell>
                  <StyledHeaderCell align="left" className="paddingStyle">Errors</StyledHeaderCell>
                  <StyledHeaderCell align="left" className="paddingStyle">Message</StyledHeaderCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={props.modal ? 6 : 7} align="center" className="paddingStyle">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={props.modal ? 6 : 7} align="center" className="paddingStyle">
                        <Typography variant="subtitle1">
                          No records found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    data.map((row, index) => {
                      let runDate = (row && row.createdAt) ? moment(row.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY'):null;
                      return (
                        <TableRow key={row.id}>
                          <TableCell align="left" colSpan={2} className="dateTlStyle">
                          {runDate}
                          </TableCell>
                          <TableCell ></TableCell>
                          <TableCell align="left" className="paddingStyle">
                            {row && row.totalItem}
                          </TableCell>
                          <TableCell align="left" className="paddingStyle">
                            {row && row.newItem}
                          </TableCell>
                          <TableCell align="left" className="paddingStyle">
                            {row && row.updatedItem}
                          </TableCell>
                          <TableCell align="left" className="paddingStyle">
                            {(row && row.status) ? <span style={{ color: (row.status === "SUCCESS") ? "green" : "red" }}>{row.status}</span> : ""}
                          </TableCell>
                          <TableCell align="left" className="paddingStyle">
                            {row && row.errorItem}
                          </TableCell>
                          <TableCell component="th" scope="row" className="paddingStyle">
                            <Typography className="errorMsgTl">
                              {(row && row.message) ? row.message : "Nil"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
        : <div style={{ paddingTop: 30 }}>
          <TableContainer component={Paper} >
            <Table style={{ paddingTop: 10 }} size="small">
              <TableHead>
                <TableRow>
                  <StyledHeaderCell align="left" colSpan={2} className="paddingStyle">Date</StyledHeaderCell>
                  <StyledHeaderCell align="left" className="paddingStyle"></StyledHeaderCell>
                  <StyledHeaderCell align="left" className="paddingStyle">{(jobType === "CUSTOMER") ? "New Customer" : "New Sales"}</StyledHeaderCell>
                  <StyledHeaderCell align="left" className="paddingStyle">
                    Status
                  </StyledHeaderCell>
                  <StyledHeaderCell align="left" className="paddingStyle">Errors</StyledHeaderCell>
                  <StyledHeaderCell align="left" className="paddingStyle">Message</StyledHeaderCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={props.modal ? 6 : 7} align="center" className="paddingStyle">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={props.modal ? 6 : 7} align="center" className="paddingStyle">
                        <Typography variant="subtitle1">
                          No records found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    data.map((row, index) => {
                      let runDate = (row && row.createdAt) ? moment(row.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY'):null;
                      return (
                        <TableRow key={row.id}>
                          <TableCell align="left" colSpan={2} className="dateTlStyle">
                            {runDate}
                          </TableCell>
                          <TableCell ></TableCell>
                          <TableCell align="left" className="paddingStyle">
                            {row && row.newItem}
                          </TableCell>
                          <TableCell align="left" className="paddingStyle">
                            {(row && row.status) ? <span style={{ color: (row.status === "SUCCESS") ? "green" : "red" }}>{row.status}</span> : ""}
                          </TableCell>
                          <TableCell align="left" className="paddingStyle">
                            {row && row.errorItem}
                          </TableCell>
                          <TableCell component="th" scope="row" className="paddingStyle">
                            <Typography className="errorMsgTl">
                              {(row && row.message) ? row.message : "Nil"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      }
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
    </div>
  );
};

export default BatchJobHistory;
