import React from "react";
import { Helmet } from "react-helmet";
import { formatDate } from '../common/util';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  print: {
    '@media print': {
      display: 'block',
      pageBreakBefore: 'always',
    },
  },
  printTogether: {
    '@media print': {
      display: 'block',
      pageBreakInside: 'avoid',
    },
  }
});

const divStyle = {
  margin: '5px',
  width: '100%',
};
const pStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '14px',
  textAlign: 'center',
  lineHeight: '1.6',
};

class CompToPrintInv extends React.Component {
  render() {
    // const { classes } = this.props;
    const paymentDetails = this.props.paymentRecInv != null && this.props.paymentRecInv;
    let invDetails = (paymentDetails.invoice && paymentDetails.invoice.customer) ? paymentDetails.invoice :
      (paymentDetails.purchaseInvoice && paymentDetails.purchaseInvoice.supplier) ? paymentDetails.purchaseInvoice : {};
    const isDR = (paymentDetails.invoice && paymentDetails.invoice.customer) ? true : false;
    const partyDetails = (paymentDetails.invoice && paymentDetails.invoice.customer) ? paymentDetails.invoice.customer :
      paymentDetails.purchaseInvoice && paymentDetails.purchaseInvoice.supplier && paymentDetails.purchaseInvoice.supplier;

    return (
      <div >
        <div align="center">
          <img alt="comLogo" src={this.props.companyPrintLogo}
            style={{ height: "100px", width: "200px" }} />
        </div>
        <div style={divStyle} align="center">
          {/* Deposit Reciept */}
          {/* <Helmet><title>Deposit Reciept</title></Helmet> */}
          <Helmet><title></title></Helmet>
          <div width="100%">
            <span style={pStyle}><b>{invDetails && invDetails.org && invDetails.org.name.toUpperCase()}</b></span><br />
            {invDetails && invDetails.org && (invDetails.org.address1.toUpperCase())}
            <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.org && (invDetails.org.address2.toUpperCase())}</div>
            <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.org && (invDetails.org.address3.toUpperCase() + '-' + invDetails.org.pincode)}</div>
            <span style={pStyle}>State :{invDetails.org && (invDetails.org.state)}  Code: {invDetails && invDetails.org && invDetails.org.GSTIN && invDetails.org.GSTIN.slice(0, 2)}<br /></span>
            <span style={pStyle}>GSTIN/UIN: {(invDetails && invDetails.org && invDetails.org.GSTIN) ? invDetails.org.GSTIN : "Not Available"}<br /></span>
            <span style={pStyle}>Email: {invDetails && invDetails.org && invDetails.org.email ? invDetails.org.email : "Not Available"}<br /><br /></span>
            <span style={pStyle}><b>Payment Voucher<br /><br /><br /></b></span>
          </div>
          <div style={divStyle}>
            <table width="80%" padding="2px">
              <tr>
                <td width="70%"><span style={pStyle}>No. : <b>{this.props.paymentRecInv && (this.props.paymentRecInv.paymentNum) ? this.props.paymentRecInv.paymentNum : this.props.paymentRecInv.id}</b></span></td>
                <td width="30%"><span style={pStyle}>Dated : <b>{this.props.paymentRecInv && formatDate(this.props.paymentRecInv.createdAt)}</b></span></td>
              </tr>
            </table>
          </div>
          <div style={divStyle}>
            <table width="80%" style={{ borderCollapse: 'collapse' }} padding="2px">
              <thead>
                <tr>
                  <td style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width="75%">&nbsp;&nbsp;Particulars</td>
                  <td style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width="25%" align="right">Amount</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  hover={true}
                >
                  <td align="left" style={{ padding: '5px', borderRight: '1px solid #ccc' }} width="75%">
                    <span style={pStyle}><b>Account : </b><br /></span>
                    <span style={pStyle}><span style={{ marginLeft: '50px' }}>{partyDetails && partyDetails.fullName}</span><br /></span>
                    <span style={pStyle} width="100%">
                      <span style={{ marginLeft: '50px' }}>Agst Ref : {invDetails.invoiceNum}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <b>{paymentDetails.paidAmount ? (Math.round(paymentDetails.paidAmount * 100) / 100).toFixed(2) : '0.00'} {isDR ? `Dr` : `Cr`}</b></span>
                    </span><br /><br />
                    <span style={pStyle}><b>Through :</b><br /><span style={{ marginLeft: '50px' }}>{paymentDetails && paymentDetails.paymentMode && paymentDetails.paymentMode.description}</span><br /></span>
                    <span style={pStyle}><b>Amount (in words) :</b><br /><span style={{ marginLeft: '50px' }}>{paymentDetails && paymentDetails.amountInWords}</span></span>
                  </td>
                  <td align="right" valign="top">
                    <br /><br /><br />{paymentDetails.paidAmount ? (Math.round(paymentDetails.paidAmount * 100) / 100).toFixed(2) : '0.00'}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" align="right" style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>{paymentDetails && paymentDetails.paidAmount ? (Math.round(paymentDetails.paidAmount * 100) / 100).toFixed(2) : '0.00'}</td>
                </tr>
                <tr>
                  <td colspan="2" align="right"><span style={pStyle}><br /><br />Authorised Signatory</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(CompToPrintInv);