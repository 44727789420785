import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Container } from "@material-ui/core";

import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import NewLogin from "./components/login/NewLogin";
import ResetPassowrd from "./components/login/ResetPassowrd";
import AdminDashboard from "./components/dashboard/AdminDashboard";
import POSDashboard from "./components/dashboard/POSDashboard";
import AccountDashboard from "./components/dashboard/AccountantDashboard";
import StatusDashboard from "./components/dashboard/StatusDashboard";
import UnAuthorized from "./components/common/UnAuthorized";

import Users from "./components/users/Users";
import Customer from "./components/customers/Customer";
import CustomersForm from "./components/customers/CustomersForm";
import CustomerHistory from "./components/customers/CustomerHistory";
import CustomerDraft from "./components/customers/CustomerDrafts";
import CustomerPurchaseOrder from "./components/customers/customerOrders/PurchaseOrder";
import CustomersDiscountForm from "./components/customers/customerDiscount/CustomerDiscount";
import Inventory from "./components/inventory/Inventory";
import InventoryMovement from "./components/inventory/InventoryMovement";
import InventoryLocation from "./components/inventory/InventoryLocation";
import InventoryProduct from "./components/inventory/InventoryProduct";
import POSProductInventory from './components/pos/POSProductInventory';
import ProductGroups from "./components/productGroups/ProductGroups";
import ManageProducts from "./components/productGroups/ManageProducts";
import ManageDiscounts from "./components/productGroups/ManageDiscounts";
import JobCard from "./components/jobs/JobCard";
import JobList from "./components/jobs/JobList";
import ManageProdGroups from "./components/products/manageProducts/ManageProducts";
import ManageProdPriceForm from "./components/products/manageProducts/ManagePrice";
import Reports from "./components/reports/Reports";
import ProductPo from "./components/reports/productPo";
import CustomerOrders from "./components/reports/customerOrders";
import RecordSalesNew from "./components/RecordSaleNew/recordSale";
import CustomerSales from "./components/reports/customerSales";
import SupplierPurchase from "./components/reports/supplierPurchase";
import PaymentEntrylists from "./components/reports/paymentEntrylists";
import JournalEntrylists from "./components/reports/journalEntrylists";
import UnsyncCustomerSales from "./components/reports/UnsyncCustomerSales";
import EInvAndEWBErrorReport from "./components/reports/eInvAndEWBErrorReport";
import CancelledInvoiceReport from "./components/reports/CancelledInvoiceReport";
import InvoicePos from "./components/reports/customerSalesPos";
import DailySync from "./components/reports/dailySync";
import ReplenishmentInsight from "./components/reports/replenishmentInsight";
import PriceReport from "./components/reports/missingPriceReport";
import Products from "./components/products/Products";
import POSCustomersForm from "./components/pos/POSCustomer";
import DailyJobSyncDashboard from "./components/dashboard/DailyJobSyncDashboard";
import ERPNextDataSyncDashboard from "./components/dashboard/erpNextDataSyncDashboard";
import BatchJobHistory from "./components/dashboard/BatchJobHistory";
import { currentUser } from "./components/login/loginSlice";
import {
  fetchRoles,
  fetchLocalities,
  fetchProductLookup,
  fetchProductGroupTypesLookup,
  fetchProductGroupsLookup,
  fetchJobLookup,
  fetchAllTechnicians,
  fetchPOStatusAndTypes,
  fetchCustStatesAndTypes,
  fetchLocations,
  fetchProducts,
  fetchInvoiceLookup,
  fetchSupTypes,
  fetchSPOStatusAndTypes,
  fetchJournalEntryAccounts,
  companyDefaultBankAndTermsConditions,
  erpNextAccountLookup
} from "./components/common/lookupSlice";

import InventoryMovementReport from "./components/reports/inventoryMovementReport";
import CustomerPreviousSales from "./components/reports/customerPreviousSales";
import ProductStatusReport from "./components/reports/ProductStatus";
import RazorpayPGW from "./components/RecordSaleNew/razorpayPGW";
import TestTable from "./components/reports/testTable";
import ERPNextTrailBalanceReport from "./components/reports/ERPNextTrailBalanceReport";
import ERPNextBalanceSheetReport from "./components/reports/ERPNextBalanceSheetReport";
import ERPNextGeneralLedgerReport from "./components/reports/ERPNextGeneralLedgerReport";
import ERPNextProfitAndLossReport from "./components/reports/ERPNextProfitAndLossReport";
import ERPNextCustomerLedgerReport from "./components/reports/ERPNextCustomerLedgerReport";
import SupplierPurchaseHome from "./components/purchase/purchaseHome";
import SalesHome from "./components/RecordSaleNew/salesHome";
import SupplierForm from "./components/purchase/supplierForm";
import SupplierList from "./components/purchase/supplierList";
import MaterialRequests from "./components/purchase/materialRequests";
import SupplierQuotationRequests from "./components/purchase/supplierQuotationRequests";
import SupplierPurchaseOrder from "./components/purchase/supplierPurchaseOrder/SupplierPurchaseOrder";
import RecordPurchase from "./components/purchase/recordPurchase";
import SupplierQuotationRequestForm from "./components/purchase/supplierQuotationRequestForm";
import DirectPaymentEntryComp from "./components/payment/directPaymentEntryComp";
import AccountsHome from "./components/payment/accountHome";
import JournalEntryComp from "./components/journalEntry/journalEntryComp";
import ConfigurationHome from "./components/configurations/configurationHome";
import CompanyForm from "./components/configurations/companyForm";
import CompanyAddressForm from "./components/configurations/companyAddressForm";
import CompanyBankDetailsForm from "./components/configurations/companyBankDetailsForm";
import CompanyBankDetailsList from "./components/configurations/companyBankDetailsList";
import CompanyAddressList from "./components/configurations/companyAddressList";
import GSTSetupUOMDetailsList from "./components/configurations/gstSetupUOMDetailsList";
import ChartOfAccountList from "./components/configurations/chartOfAccountList";
import CostCenterList from "./components/configurations/costCenterList";
import CreditNoteComp from "./components/creditNote/creditNoteComp";
import CreditNoteListComp from "./components/reports/creditNoteListComp";
import DebitNoteComp from "./components/debitNote/debitNoteComp";
import DebitNoteListComp from "./components/reports/debitNoteListComp";
import CompanyTermsCondionsDetailFormComp from "./components/configurations/companyTermsConditionsDetailForm";
import CompanyTermsAndCondListComp from "./components/configurations/companyTermsAndCondList";
import BankAccountStatementImportDetailsList from "./components/configurations/bankAccountStatementImportDetailsList";
import BankAccountERPNextDetailsForm from "./components/configurations/bankAccountERPNextDetailsForm";
import BankAccountTransactionERPNextForm from "./components/configurations/bankAccountTransactionERPNextForm";
import ERPNextBankReconciliationStatement from "./components/reports/ERPNextBankReconciliationStatement";
import UserGuideComp from "./components/common/userGuideComp";
import "./App.css";

const App = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(currentUser);
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isAdmin =
    roles.findIndex((role) => role.name === "ADMIN") > -1 ? true : false;
  const isServiceAdvisor =
    roles.findIndex((role) => role.name === "SERVICE ADVISOR") > -1
      ? true
      : false;
  const isTechnician =
    roles.findIndex((role) => role.name === "TECHNICIAN") > -1 ? true : false;
  const isBillingAgent =
    roles.findIndex((role) => role.name === "BILLING AGENT") > -1 ? true : false;
  const isAuditor =
    roles.findIndex((role) => role.name === "AUDITOR") > -1 ? true : false;
  const isAccountant =
    roles.findIndex((role) => role.name === "ACCOUNTANT") > -1 ? true : false;

  useEffect(() => {
    if (loggedInUser) {
      dispatch(fetchRoles());
      dispatch(fetchLocalities());
      dispatch(fetchLocations());
      dispatch(fetchProductLookup());
      dispatch(fetchProductGroupTypesLookup());
      if (isAdmin || isServiceAdvisor) {
        dispatch(fetchProductGroupsLookup());
      }
      dispatch(fetchJobLookup());
      if (isAdmin || isServiceAdvisor) {
        dispatch(fetchAllTechnicians());
      }
      dispatch(fetchPOStatusAndTypes());
      dispatch(fetchCustStatesAndTypes());
      dispatch(fetchProducts());
      dispatch(fetchInvoiceLookup());
      dispatch(fetchSupTypes());
      dispatch(fetchSPOStatusAndTypes());
      dispatch(fetchJournalEntryAccounts());
      dispatch(companyDefaultBankAndTermsConditions());
      dispatch(erpNextAccountLookup());
    }
  }, [loggedInUser]); // eslint-disable-line react-hooks/exhaustive-deps

  return !loggedInUser ? (
    <div>
      <Router>
        <Container>
          <div className="login-content">
            <Switch>
              <Route
                path={["/"]}
                exact
                component={NewLogin}
              />
              <Route path="/resetpassword" exact component={ResetPassowrd} />
            </Switch>
          </div>
        </Container>
      </Router>
      <Footer />
    </div>
  ) : (
    <div>
      <Router>
        <Header loggedInUser={loggedInUser} />
        <Container>
          <div className="body-content">
            {isAdmin || isAuditor ? (
              <Switch>
                <Route
                  path={["/", "/dashboard", "/home"]}
                  exact
                  component={AdminDashboard}
                />
                <Route path="/pos" exact component={POSDashboard} />
                <Route path="/users" exact component={Users} />
                <Route path="/customers" exact component={Customer} />
                <Route path="/products" exact component={Products} />
                <Route path="/inventory" exact component={Inventory} />
                <Route path="/inventoryLocation" exact component={InventoryLocation} />
                <Route path="/inventoryProduct" exact component={InventoryProduct} />
                <Route path="/posProdInventory" exact component={POSProductInventory} />
                <Route path="/inventoryMovement" exact component={InventoryMovement} />
                <Route path="/reports" exact component={Reports} />
                <Route path="/productPurchaseOrders" exact component={ProductPo} />
                <Route path="/customerOrders" exact component={CustomerOrders} />
                <Route path="/customerSales" exact component={CustomerSales} />
                <Route path="/supplierpurchase" exact component={SupplierPurchase} />
                <Route path="/paymententrylists" exact component={PaymentEntrylists} />
                <Route path="/journallists" exact component={JournalEntrylists} />
                <Route path="/replenishmentInsight" exact component={ReplenishmentInsight} />
                <Route path="/priceReport" exact component={PriceReport} />
                <Route path="/inventorySync" exact component={DailySync} />
                <Route path="/invMovementReport" exact component={InventoryMovementReport} />
                <Route path="/unSyncCustSales" exact component={UnsyncCustomerSales} />
                <Route path="/eInvEWBErrorReport" exact component={EInvAndEWBErrorReport} />
                <Route path="/cancelledInvReport" exact component={CancelledInvoiceReport} />
                <Route path="/productGroups" exact component={ProductGroups} />
                <Route path="/manageProducts" exact component={ManageProducts} />
                <Route path="/recordSales/:id" exact component={RecordSalesNew} />
                <Route path="/recordSales" exact component={RecordSalesNew} />
                <Route path="/manageDiscounts" exact component={ManageDiscounts} />
                <Route path="/customerForm/:id" exact component={CustomersForm} />
                <Route path="/customerDiscount/:id" exact component={CustomersDiscountForm} />
                <Route path="/customerForm" exact component={CustomersForm} />
                <Route path="/customerHistory/:id" exact component={CustomerHistory} />
                <Route path="/customerOrder/:id" exact component={CustomerPurchaseOrder} />
                <Route path="/manageProdGroups/:id" exact component={ManageProdGroups} />
                <Route path="/manageProdPriceForm/:id" exact component={ManageProdPriceForm} />
                <Route path="/customerDrafts/:id" exact component={CustomerDraft} />
                <Route
                  path="/jobs/:id"
                  key="viewJob"
                  exact
                  render={(props) => <JobCard {...props} />}
                />
                <Route
                  path="/jobs"
                  key="jobList"
                  exact
                  render={(props) => <JobList {...props} />}
                />
                <Route
                  path="/job"
                  key="unAuthorizedFeature"
                  exact
                  component={UnAuthorized}
                />
                <Route
                  path="/datasync"
                  exact
                  component={DailyJobSyncDashboard}
                />
                 <Route
                  path="/erpnextdatasync"
                  exact
                  component={ERPNextDataSyncDashboard}
                />
                <Route
                  path="/jobhistory"
                  exact
                  component={BatchJobHistory}
                />
                <Route
                  path="/customerprevioussales"
                  exact
                  component={CustomerPreviousSales}
                />
                <Route
                  path="/productstatusreport"
                  exact
                  component={ProductStatusReport}
                />
                <Route
                  path="/razorpayPGW"
                  exact
                  component={RazorpayPGW}
                />
                <Route
                  path="/testtable"
                  exact
                  component={TestTable}
                />
                <Route
                  path="/trailBalanceReport"
                  exact
                  component={ERPNextTrailBalanceReport}
                />
                <Route
                  path="/balanceSheetReport"
                  exact
                  component={ERPNextBalanceSheetReport}
                />
                <Route
                  path="/generalLedgerReport"
                  exact
                  component={ERPNextGeneralLedgerReport}
                />
                <Route
                  path="/profitAndLossReport"
                  exact
                  component={ERPNextProfitAndLossReport}
                />
                <Route
                  path="/customerLedgerReport"
                  exact
                  component={ERPNextCustomerLedgerReport}
                />
                <Route
                  path="/purchasehome"
                  exact
                  component={SupplierPurchaseHome}
                />
                <Route
                  path="/saleshome"
                  exact
                  component={SalesHome}
                />
                <Route path="/supplierform"
                  exact
                  component={SupplierForm} />
                <Route path="/supplierform/:id"
                  exact
                  component={SupplierForm} />
                <Route path="/suppliers"
                  exact
                  component={SupplierList} />
                <Route path="/materialrequests"
                  exact
                  component={MaterialRequests} />
                <Route path="/supplierquotationrequests"
                  exact
                  component={SupplierQuotationRequests} />
                <Route path="/supplierquotationrequestsform"
                  exact
                  component={SupplierQuotationRequestForm} />
                <Route path="/supplierpurchaseorder/:id"
                  exact
                  component={SupplierPurchaseOrder} />
                <Route path="/recordpurchases"
                  exact
                  component={RecordPurchase} />
                <Route path="/recordpurchases/:id"
                  exact
                  component={RecordPurchase} />
                <Route path="/paymentsentries"
                  exact
                  component={DirectPaymentEntryComp} />
                <Route path="/accountshome"
                  exact
                  component={AccountsHome} />
                <Route path="/journalentries"
                  exact
                  component={JournalEntryComp} />
                <Route path="/configrationhome"
                  exact
                  component={ConfigurationHome} />
                <Route path="/companyconfig/:id"
                  exact
                  component={CompanyForm} />
                <Route path="/companyconfigaddress"
                  exact
                  component={CompanyAddressForm} />
                <Route path="/companyconfigaddress/:id"
                  exact
                  component={CompanyAddressForm} />
                <Route path="/companyconfigbankdetails"
                  exact
                  component={CompanyBankDetailsForm} />
                <Route path="/companyconfigbankdetails/:id"
                  exact
                  component={CompanyBankDetailsForm} />
                <Route path="/companyconfigbankdetaillist"
                  exact
                  component={CompanyBankDetailsList} />
                <Route path="/companyconfigaddresslist"
                  exact
                  component={CompanyAddressList} />
                <Route path="/gstsetupconfig"
                  exact
                  component={GSTSetupUOMDetailsList} />
                <Route path="/chartofaccountsconfig"
                  exact
                  component={ChartOfAccountList} />
                <Route path="/costcentersconfig"
                  exact
                  component={CostCenterList} />
                <Route path="/creditnote/:id"
                  exact
                  component={CreditNoteComp} />
                <Route path="/creditnote"
                  exact
                  component={CreditNoteComp} />
                <Route path="/creditnotelist"
                  exact
                  component={CreditNoteListComp} />
                <Route path="/debitnote"
                  exact
                  component={DebitNoteComp} />
                <Route path="/debitnotelist"
                  exact
                  component={DebitNoteListComp} />
                <Route path="/orgconfigtermscond/:id"
                  exact
                  component={CompanyTermsCondionsDetailFormComp} />
                <Route path="/orgconfigtermscond"
                  exact
                  component={CompanyTermsCondionsDetailFormComp} />
                <Route path="/orgconfigtermscondlist"
                  exact
                  component={CompanyTermsAndCondListComp} />
                <Route path="/bankaccountconfig"
                  exact
                  component={BankAccountERPNextDetailsForm} />
                <Route path="/bankaccountstatementimportlist"
                  exact
                  component={BankAccountStatementImportDetailsList} />
                <Route path="/uploadbanktransaction"
                  exact
                  component={BankAccountTransactionERPNextForm} />
                <Route
                  path="/bankReconciliationStatement"
                  exact
                  component={ERPNextBankReconciliationStatement}
                />
                  <Route
                  path="/userguides"
                  exact
                  component={UserGuideComp}
                />
                <Route key="somethingElse" component={AdminDashboard} />
              </Switch>
            ) : (
              <></>
            )}
            {isServiceAdvisor ? (
              <Switch>
                <Route
                  path={["/", "/dashboard", "/home"]}
                  exact
                  component={StatusDashboard}
                />

                <Route
                  path="/job"
                  key="newJob"
                  exact
                  render={(props) => <JobCard {...props} isNew />}
                />
                <Route
                  path="/jobs/:id"
                  key="editJob"
                  exact
                  render={(props) => <JobCard {...props} />}
                />
                <Route
                  path="/jobs"
                  key="jobList"
                  exact
                  render={(props) => <JobList {...props} />}
                />
                <Route path="/customerForm" exact component={CustomersForm} />
                <Route
                  path={["/users", "/customers", "/products"]}
                  key="unAuthorizedFeature"
                  exact
                  component={UnAuthorized}
                />
                <Route key="somethingElse" component={StatusDashboard} />
              </Switch>
            ) : (
              <></>
            )}
            {isTechnician ? (
              <Switch>
                <Route
                  path="/jobs/:id"
                  key="editJob"
                  exact
                  render={(props) => <JobCard {...props} />}
                />
                <Route
                  path={["/", "/jobs", "/home", "dashboard"]}
                  key="jobList"
                  exact
                  render={(props) => <JobList {...props} />}
                />
                <Route
                  path="/job"
                  key="newJob"
                  exact
                  component={UnAuthorized}
                />

                <Route
                  path={["/users", "/customers", "/products", "/newJob"]}
                  key="unAuthorizedFeature"
                  exact
                  component={UnAuthorized}
                />
                <Route
                  key="somethingElse"
                  render={(props) => <JobList {...props} />}
                />
              </Switch>
            ) : (
              <></>
            )}{
              isBillingAgent ? (
                <Switch>
                  <Route
                    path={["/", "/pos", "/home"]}
                    exact
                    component={POSDashboard}
                  />
                  <Route path="/posProdInventory" exact component={POSProductInventory} />
                  <Route path="/invoicePos" exact component={InvoicePos} />
                  <Route path="/recordSales/:id" exact component={RecordSalesNew} />
                  <Route path="/recordSales" exact component={RecordSalesNew} />
                  <Route path="/posCustomerForm" exact component={POSCustomersForm} />
                  <Route path="/posCustomerForm/:id" exact component={POSCustomersForm} />
                  <Route key="somethingElse" component={POSDashboard} />
                </Switch>) : (<></>)
            }
            {
              isAccountant ? (
                <Switch>
                  <Route
                    path={["/", "/dashboard", "/home"]}
                    exact
                    component={AccountDashboard}
                  />
                  <Route path="/pos" exact component={POSDashboard} />
                  <Route path="/users" exact component={Users} />
                  <Route path="/customers" exact component={Customer} />
                  <Route path="/products" exact component={Products} />
                  <Route path="/inventory" exact component={Inventory} />
                  <Route path="/inventoryLocation" exact component={InventoryLocation} />
                  <Route path="/inventoryProduct" exact component={InventoryProduct} />
                  <Route path="/posProdInventory" exact component={POSProductInventory} />
                  <Route path="/inventoryMovement" exact component={InventoryMovement} />
                  <Route path="/reports" exact component={Reports} />
                  <Route path="/productPurchaseOrders" exact component={ProductPo} />
                  <Route path="/customerOrders" exact component={CustomerOrders} />
                  <Route path="/customerSales" exact component={CustomerSales} />
                  <Route path="/supplierpurchase" exact component={SupplierPurchase} />
                  <Route path="/paymententrylists" exact component={PaymentEntrylists} />
                  <Route path="/journallists" exact component={JournalEntrylists} />
                  <Route path="/replenishmentInsight" exact component={ReplenishmentInsight} />
                  <Route path="/priceReport" exact component={PriceReport} />
                  <Route path="/inventorySync" exact component={DailySync} />
                  <Route path="/invMovementReport" exact component={InventoryMovementReport} />
                  <Route path="/unSyncCustSales" exact component={UnsyncCustomerSales} />
                  <Route path="/eInvEWBErrorReport" exact component={EInvAndEWBErrorReport} />
                  <Route path="/cancelledInvReport" exact component={CancelledInvoiceReport} />
                  <Route path="/productGroups" exact component={ProductGroups} />
                  <Route path="/manageProducts" exact component={ManageProducts} />
                  <Route path="/recordSales/:id" exact component={RecordSalesNew} />
                  <Route path="/recordSales" exact component={RecordSalesNew} />
                  <Route path="/manageDiscounts" exact component={ManageDiscounts} />
                  <Route path="/customerForm/:id" exact component={CustomersForm} />
                  <Route path="/customerDiscount/:id" exact component={CustomersDiscountForm} />
                  <Route path="/customerForm" exact component={CustomersForm} />
                  <Route path="/customerHistory/:id" exact component={CustomerHistory} />
                  <Route path="/customerOrder/:id" exact component={CustomerPurchaseOrder} />
                  <Route path="/manageProdGroups/:id" exact component={ManageProdGroups} />
                  <Route path="/manageProdPriceForm/:id" exact component={ManageProdPriceForm} />
                  <Route path="/customerDrafts/:id" exact component={CustomerDraft} />
                  <Route
                    path="/jobs/:id"
                    key="viewJob"
                    exact
                    render={(props) => <JobCard {...props} />}
                  />
                  <Route
                    path="/jobs"
                    key="jobList"
                    exact
                    render={(props) => <JobList {...props} />}
                  />
                  <Route
                    path="/job"
                    key="unAuthorizedFeature"
                    exact
                    component={UnAuthorized}
                  />
                  <Route
                    path="/datasync"
                    exact
                    component={DailyJobSyncDashboard}
                  />
                  <Route
                    path="/jobhistory"
                    exact
                    component={BatchJobHistory}
                  />
                  <Route
                    path="/customerprevioussales"
                    exact
                    component={CustomerPreviousSales}
                  />
                  <Route
                    path="/productstatusreport"
                    exact
                    component={ProductStatusReport}
                  />
                  <Route
                    path="/razorpayPGW"
                    exact
                    component={RazorpayPGW}
                  />
                  <Route
                    path="/testtable"
                    exact
                    component={TestTable}
                  />
                  <Route
                    path="/trailBalanceReport"
                    exact
                    component={ERPNextTrailBalanceReport}
                  />
                  <Route
                    path="/balanceSheetReport"
                    exact
                    component={ERPNextBalanceSheetReport}
                  />
                  <Route
                    path="/generalLedgerReport"
                    exact
                    component={ERPNextGeneralLedgerReport}
                  />
                  <Route
                    path="/profitAndLossReport"
                    exact
                    component={ERPNextProfitAndLossReport}
                  />
                  <Route
                    path="/customerLedgerReport"
                    exact
                    component={ERPNextCustomerLedgerReport}
                  />
                  <Route
                    path="/purchasehome"
                    exact
                    component={SupplierPurchaseHome}
                  />
                  <Route
                    path="/saleshome"
                    exact
                    component={SalesHome}
                  />
                  <Route path="/supplierform"
                    exact
                    component={SupplierForm} />
                  <Route path="/supplierform/:id"
                    exact
                    component={SupplierForm} />
                  <Route path="/suppliers"
                    exact
                    component={SupplierList} />
                  <Route path="/materialrequests"
                    exact
                    component={MaterialRequests} />
                  <Route path="/supplierquotationrequests"
                    exact
                    component={SupplierQuotationRequests} />
                  <Route path="/supplierquotationrequestsform"
                    exact
                    component={SupplierQuotationRequestForm} />
                  <Route path="/supplierpurchaseorder/:id"
                    exact
                    component={SupplierPurchaseOrder} />
                  <Route path="/recordpurchases"
                    exact
                    component={RecordPurchase} />
                  <Route path="/recordpurchases/:id"
                    exact
                    component={RecordPurchase} />
                  <Route path="/paymentsentries"
                    exact
                    component={DirectPaymentEntryComp} />
                  <Route path="/accountshome"
                    exact
                    component={AccountsHome} />
                  <Route path="/journalentries"
                    exact
                    component={JournalEntryComp} />
                  <Route path="/configrationhome"
                    exact
                    component={ConfigurationHome} />
                  <Route path="/companyconfig/:id"
                    exact
                    component={CompanyForm} />
                  <Route path="/companyconfigaddress"
                    exact
                    component={CompanyAddressForm} />
                  <Route path="/companyconfigaddress/:id"
                    exact
                    component={CompanyAddressForm} />
                  <Route path="/companyconfigbankdetails"
                    exact
                    component={CompanyBankDetailsForm} />
                  <Route path="/companyconfigbankdetails/:id"
                    exact
                    component={CompanyBankDetailsForm} />
                  <Route path="/companyconfigbankdetaillist"
                    exact
                    component={CompanyBankDetailsList} />
                  <Route path="/companyconfigaddresslist"
                    exact
                    component={CompanyAddressList} />
                  <Route path="/gstsetupconfig"
                    exact
                    component={GSTSetupUOMDetailsList} />
                  <Route path="/chartofaccountsconfig"
                    exact
                    component={ChartOfAccountList} />
                  <Route path="/costcentersconfig"
                    exact
                    component={CostCenterList} />
                  <Route path="/creditnote/:id"
                    exact
                    component={CreditNoteComp} />
                  <Route path="/creditnote"
                    exact
                    component={CreditNoteComp} />
                  <Route path="/creditnotelist"
                    exact
                    component={CreditNoteListComp} />
                  <Route path="/debitnote"
                    exact
                    component={DebitNoteComp} />
                  <Route path="/debitnotelist"
                    exact
                    component={DebitNoteListComp} />
                  <Route path="/orgconfigtermscond/:id"
                    exact
                    component={CompanyTermsCondionsDetailFormComp} />
                  <Route path="/orgconfigtermscond"
                    exact
                    component={CompanyTermsCondionsDetailFormComp} />
                  <Route path="/orgconfigtermscondlist"
                    exact
                    component={CompanyTermsAndCondListComp} />
                  <Route path="/bankaccountconfig"
                    exact
                    component={BankAccountERPNextDetailsForm} />
                  <Route path="/bankaccountstatementimportlist"
                    exact
                    component={BankAccountStatementImportDetailsList} />
                  <Route path="/uploadbanktransaction"
                    exact
                    component={BankAccountTransactionERPNextForm} />
                  <Route
                    path="/bankReconciliationStatement"
                    exact
                    component={ERPNextBankReconciliationStatement}
                  />
                  <Route key="somethingElse" component={AccountDashboard} />
                </Switch>
              ) : (<></>)
            }
          </div>
        </Container>
      </Router>
      <Footer />
    </div>
  );
};

export default App;
