import Home from "../../images/HomeMenu.svg";
import Inventory from "../../images/Inventory 24x24.svg";
import Reports from "../../images/ReportsMenu.svg";
import ProductGroups from "../../images/ProductGroupsMenu.svg";
import Products from "../../images/ProductMenu.svg";
import Customers from "../../images/CustomerMenu.svg";
import ServiceRequest from "../../images/ServiceTool.svg";
import Accounting24 from "../../images/Accounting 24x24.svg";
import Configuration24 from "../../images/Configuration 24x24.svg";
import Sales24 from "../../images/Sales New 24x24.svg";
import Suppliers24 from "../../images/Suppliers 24x24.svg";
import Purchase24 from "../../images/Purchase New 24x24.svg";
import Users from "../../images/UserMenu.svg";
import BatchJobs from "../../images/BatchJobsMenu.svg";

export const pages = [
  {
    title: 'Home',
    href: '/',
    icon: Home
  },
  {
    title: 'Inventory',
    href: '/inventory',
    icon: Inventory,
    items: [
      {
        title: 'Inventory Reconcile - Location',
        href: '/inventoryLocation'
      },
      {
        title: 'Inventory Reconcile - Product',
        href: '/inventoryProduct'
      }
    ]
  },
  {
    title: 'Product Groups',
    href: '/productGroups',
    icon: ProductGroups,
    items: [
      {
        title: 'Manage Products',
        href: '/manageProducts'
      },
      {
        title: 'Manage Discounts',
        href: '/manageDiscounts'
      }
    ]
  },
  {
    title: 'Products',
    href: '/products',
    icon: Products,
    items: [
      {
        title: 'Manage Products',
        href: '/manageProducts'
      },
    ]
  },
  {
    title: 'Reports',
    href: '/reports',
    icon: Reports,
    items: [
      {
        title: 'Customer Purchase Orders',
        href: '/customerOrders'
      },
      {
        title: 'Customer Sales',
        href: '/customerSales'
      },
      {
        title: 'Unsync Customer Sales',
        href: '/unSyncCustSales'
      },
      {
        title: 'Cancelled Invoice Report',
        href: '/cancelledInvReport'
      },
      {
        title: 'Daily Inventory Sync',
        href: '/inventorySync'
      },
      {
        title: 'Inventory Movement Report',
        href: '/invMovementReport'
      }

    ]
  },
  {
    title: 'Sales',
    href: '/saleshome',
    icon: Sales24
  },
  {
    title: 'Customers',
    href: '/customers',
    icon: Customers
  },
  {
    title: 'Purchase',
    href: '/purchasehome',
    icon: Purchase24
  },
  {
    title: 'Suppliers',
    href: '/suppliers',
    icon: Suppliers24
  },
  {
    title: 'Accounting',
    href: '/accountshome',
    icon: Accounting24
  },
  {
    title: 'Configuration',
    href: '/configrationhome',
    icon: Configuration24
  },
  {
    title: 'Service Requests',
    href: '/jobs',
    icon: ServiceRequest
  },
  {
    title: 'Users',
    href: '/users',
    icon: Users
  },
  {
    title: "Data Management",
    href: "/datasync",
    icon: BatchJobs
  },
];

export const accountantPages = [
  {
    title: 'Home',
    href: '/',
    icon: Home
  },
  {
    title: 'Sales',
    href: '/saleshome',
    icon: Sales24
  },
  {
    title: 'Customers',
    href: '/customers',
    icon: Customers
  },
  {
    title: 'Purchase',
    href: '/purchasehome',
    icon: Purchase24
  },
  {
    title: 'Suppliers',
    href: '/suppliers',
    icon: Suppliers24
  },
  {
    title: 'Accounting',
    href: '/accountshome',
    icon: Accounting24
  },
  {
    title: 'Reports',
    href: '/reports',
    icon: Reports,
    items: [
      {
        title: 'Customer Purchase Orders',
        href: '/customerOrders'
      },
      {
        title: 'Customer Sales',
        href: '/customerSales'
      },
      {
        title: 'Unsync Customer Sales',
        href: '/unSyncCustSales'
      },
      {
        title: 'Cancelled Invoice Report',
        href: '/cancelledInvReport'
      },
      {
        title: 'Daily Inventory Sync',
        href: '/inventorySync'
      },
      {
        title: 'Inventory Movement Report',
        href: '/invMovementReport'
      }

    ]
  },
];

export const supplySubTypes = [{ name: "Exhibition or fairs" },
{ name: "Export" },
{ name: "For own Use" },
{ name: "Job Work" },
{ name: "Line Sales" },
{ name: "Recipient not known" },
{ name: "SKD/CKD/Lots" },
{ name: "Supply" },
{ name: "Others" }];

export const vehicleTypes = [{ id: "O", name: "ODC" },
{ id: "R", name: "Regular" }];

export const supplyTypes = [{ name: "Inward" },
{ name: "Outword" }];

export const transportModes = [{ id: "1", name: "Road" },
{ id: "2", name: "Rail" },
{ id: "3", name: "Air" },
{ id: "4", name: "Ship" }];

export const receiverEmailId = "rvikas@Dynamixedigital.com"


export const dataWithSubRows = [
  {
    firstName: 'Maria',
    lastName: 'Anders',
    age: 24,
    visits: 28,
    progress: 56,
    status: 'complicated',
    subRows: [
      {
        firstName: 'newspaper',
        lastName: 'dinner',
        age: 25,
        visits: 2,
        progress: 78,
        status: 'single',
        subRows: undefined,
      },
      {
        firstName: 'whip',
        lastName: 'marriage',
        age: 3,
        visits: 95,
        progress: 65,
        status: 'single',
        subRows: undefined,
      },
      {
        firstName: 'bee',
        lastName: 'invention',
        age: 7,
        visits: 83,
        progress: 68,
        status: 'complicated',
        subRows: undefined,
      },
    ],
  },
  {
    firstName: 'Francisco',
    lastName: 'Chang',
    age: 9,
    visits: 90,
    progress: 77,
    status: 'single',
    subRows: [
      {
        firstName: 'beds',
        lastName: 'fairies',
        age: 16,
        visits: 82,
        progress: 87,
        status: 'single',
        subRows: undefined,
      },
      {
        firstName: 'cloth',
        lastName: 'control',
        age: 11,
        visits: 8,
        progress: 75,
        status: 'single',
        subRows: undefined,
      },
    ],
  },
  {
    firstName: 'Roland',
    lastName: 'Mendel',
    age: 1,
    visits: 16,
    progress: 56,
    status: 'single',
    subRows: [
      {
        firstName: 'newspaper',
        lastName: 'dinner',
        age: 25,
        visits: 2,
        progress: 78,
        status: 'single',
        subRows: undefined,
      },
      {
        firstName: 'whip',
        lastName: 'marriage',
        age: 3,
        visits: 95,
        progress: 65,
        status: 'single',
        subRows: undefined,
      },
      {
        firstName: 'bee',
        lastName: 'invention',
        age: 7,
        visits: 83,
        progress: 68,
        status: 'complicated',
        subRows: undefined,
      },
      {
        firstName: 'stage',
        lastName: 'cherries',
        age: 14,
        visits: 94,
        progress: 53,
        status: 'relationship',
        subRows: undefined,
      },
    ],
  },
  {
    firstName: 'Helen',
    lastName: 'Bennett',
    age: 43,
    visits: 94,
    progress: 53,
    status: 'single',
    subRows: [
      {
        firstName: 'scarf',
        lastName: 'requirement',
        age: 23,
        visits: 93,
        progress: 40,
        status: 'relationship',
        subRows: undefined,
      },
    ],
  },
  {
    firstName: 'Yoshi ',
    lastName: 'Tannamuri',
    age: 37,
    visits: 85,
    progress: 28,
    status: 'single',
    subRows: [
      {
        firstName: 'tray',
        lastName: 'chemistry',
        age: 29,
        visits: 55,
        progress: 87,
        status: 'relationship',
        subRows: undefined,
      },
      {
        firstName: 'fiction',
        lastName: 'grade',
        age: 27,
        visits: 98,
        progress: 15,
        status: 'complicated',
        subRows: undefined,
      },
    ],
  },
]

export const partyTypes = [
  {
    code: "CUSTOMER",
    description: "Customer",
  },
  {
    code: "SUPPLIER",
    description: "Supplier",
  }
]


export const gstCategories = [
  {
    code: "Registered Regular",
    label: "Registered Regular",
  },
  {
    code: "Registered Composition",
    label: "Registered Composition",
  },
  {
    code: "Unregistered",
    label: "Unregistered",
  },
  {
    code: "SEZ",
    label: "SEZ",
  },
  {
    code: "Overseas",
    label: "Overseas",
  },
  {
    code: "Deemed Export",
    label: "Deemed Export",
  },
  {
    code: "UIN Holders",
    label: "UIN Holders",
  },
  {
    code: "Tax Deductor",
    lbel: "Tax Deductor",
  }
]

export const addressTypes = [
  {
    code: "Billing",
    label: "Billing",
  },
  {
    code: "Shipping",
    label: "Shipping",
  },
  {
    code: "Warehouse",
    label: "Warehouse",
  },
  {
    code: "Office",
    label: "Office",
  }
]

export const accountTypes = [
  {
    code: "Asset",
    label: "Asset",
  },
  {
    code: "Liability",
    label: "Liability",
  },
  {
    code: "Income",
    label: "Income",
  },
  {
    code: "Expense",
    label: "Expense",
  },
  {
    code: "Equity",
    label: "Equity",
  }
]

export const termsCondBelongingTypes = [
  {
    code: "Sale Invoice",
    label: "Sale Invoice",
  },
  {
    code: "Purchase Invoice",
    label: "Purchase Invoice",
  },
  {
    code: "Customer Purchase Order",
    label: "Customer Purchase Order",
  },
  {
    code: "Supplier Purchase Order",
    label: "Supplier Purchase Order",
  },
  {
    code: "Credit Note",
    label: "Credit Note",
  },
  {
    code: "Debit Note",
    label: "Debit Note",
  },
  {
    code: "Customer Purchase Quotation",
    label: "Customer Purchase Quotation",
  },
  {
    code: "Supplier Purchase Quotation",
    label: "Supplier Purchase Quotation",
  },
  {
    code: "Supplier Purchase Quotation Request",
    label: "Supplier Purchase Quotation Request",
  },
  {
    code: "Job Card",
    label: "Job Card",
  },
  {
    code: "Job Estimate",
    label: "Job Estimate",
  },
]


export const sampleBankTransactionTemplate =[{
  "Date":"01-08-2024",
  "Deposit":"100",
  "Withdrawal":"0",
  "Description":"Test Sample - 1",
  "Reference Number":"REFKGB001",
  "Bank Account":"Current Account - Karnataka Gramin Bank",
  "Currency":"INR"
},
{
  "Date":"02-08-2024",
  "Deposit":"100",
  "Withdrawal":"0",
  "Description":"Test Sample - 2",
  "Reference Number":"REFKGB002",
  "Bank Account":"Current Account - Karnataka Gramin Bank",
  "Currency":"INR"
},
{
  "Date":"03-08-2024",
  "Deposit":"0",
  "Withdrawal":"100",
  "Description":"Test Sample - 3",
  "Reference Number":"REFKGB003",
  "Bank Account":"Current Account - Karnataka Gramin Bank",
  "Currency":"INR"
},
{
  "Date":"04-08-2024",
  "Deposit":"100",
  "Withdrawal":"0",
  "Description":"Test Sample - 4",
  "Reference Number":"REFKGB004",
  "Bank Account":"Current Account - Karnataka Gramin Bank",
  "Currency":"INR"
}];
